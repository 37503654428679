<template>
    <v-container>
        <v-row>
            <v-col class="pt-0">
                <h2 class="primary--text">
                    Lots
                </h2>
            </v-col>
        </v-row>

        <!-- Quick search and pagination -->
        <v-row class="pb-4" align="center" no-gutters>
            <v-col cols="12" md="2" class="mb-2 mb-md-0">
                <v-text-field v-model="quickSearch" @keydown.enter="fetchProducts(true)" label="Réf, Ville, CP, Programme..." dense hide-details outlined clearable>
                    <template v-slot:append>
                        <v-icon color="grey" @click="fetchProducts(true)"> fas fa-search </v-icon>
                    </template>
                </v-text-field>
            </v-col>

            <v-col cols="12" md="3">
                <v-btn class="mr-4 ml-0 ml-md-4 mb-2 mb-md-0" @click="displayAdvanceSearch = !displayAdvanceSearch" color="primary" outlined depressed small>
                    <v-icon v-if="!displayAdvanceSearch" left x-small> fas fa-plus </v-icon>
                    <v-icon v-if="displayAdvanceSearch" left x-small> fas fa-minus </v-icon>
                    de filtres
                </v-btn>

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn small text class="mb-2 mb-md-0 grey lighten-2" v-on="on">
                            {{ productsCount }}
                        </v-btn>
                    </template>
                    Nombre total de lots
                </v-tooltip>

                <v-tooltip v-if="embeded" top>
                    <template v-slot:activator="{ on }">
                        <v-btn class="ml-2" :href="getExportUrl()" target="_blank" :color="getExportColor()" icon dark v-on="on">
                            <v-icon> {{ getExportIcon() }} </v-icon>
                        </v-btn>
                    </template>
                    {{ getExportTooltip() }}
                </v-tooltip>

            </v-col>

            <v-col cols="12" md="7" class="text-right">
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-icon v-show="order === 'price'" class="mr-3" color="primary" v-on="on">
                            fas fa-info-circle
                        </v-icon>
                    </template>
                    Le tri est effectué par les prix en TVA 20%. <br>
                    Les prix en TVA réduite ne sont pas pris en compte.
                </v-tooltip>

                <v-select class="sort-pagination d-inline-flex" v-model="order" label="Trier par" item-text="name" item-value="key" :menu-props="{ bottom: true, offsetY: true }" :items="sortList" background-color="white" @change="fetchProducts(true)" hide-details outlined dense>
                    <template v-slot:append-outer>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn @click="toggleOrder()" icon v-on="on" class="ml-2">
                                    <v-icon v-bind:class="{'fa-rotate-180': by === 'asc'}">
                                        fas {{ by === 'asc' ? 'fa-sort-amount-down-alt': 'fa-sort-amount-down' }}
                                    </v-icon>
                                </v-btn>
                            </template>
                            <template v-if="by === 'asc'">
                                Croissant
                            </template>
                            <template v-if="by === 'desc'">
                                Décroissant
                            </template>
                        </v-tooltip>
                    </template>
                </v-select>

                <v-pagination v-model="page" @input="fetchProducts(true, false)" :length="totalPages" :total-visible="7" class="pagination d-inline-block" color="primary" />
            </v-col>
        </v-row>

        <!-- Advance search -->
        <v-expand-transition>
            <v-card v-show="displayAdvanceSearch" elevation="0">
                <v-sheet color="grey lighten-3" class="pa-4 mb-4">
                    <span class="caption grey--text text--darken-1"> Lot </span>
                    <v-row class="mt-3">
                        <v-col cols="12" lg="3">
                            <ProductTypeSelect v-model="typesFilter" label="Nature" multiple />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <ProductRoomsSelect v-model="roomsFilter" multiple label="Typologie" />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <v-range-slider label="Budget (k€)" v-model="budgetFilter" :thumb-size="35" thumb-label="always" min="0" max="1000" step="10" />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <ProductStatusSelect v-model="statusesFilter" multiple />
                        </v-col>
                    </v-row>

                    <span v-show="!embeded" class="caption grey--text text--darken-1"> Programme </span>
                    <v-row v-show="!embeded" class="mt-0">
                        <v-col cols="12" lg="3">
                            <ProgramAutocomplete ref="programAutocomplete" v-model="programsFilter" label="Programmes" multiple hideArchived noDynamicSearch />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <ProgramLocationSelect v-model="locationsFilter" :places="places" label="Localisation" />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <ProgramFiscaliteSelect v-model="fiscalitesFilter" multiple label="Fiscalités" />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <DatePickerMenu v-model="beforeDeliveryDateFilter" endOfMonth label="Date de Livraison" type="month" />
                        </v-col>
                    </v-row>

                    <span class="caption grey--text text--darken-1"> Activités </span>
                    <v-row class="mt-0">
                        <v-col cols="12" lg="3">
                            <ProductActivitySelect v-model="activitiesFilter" label="Activités" multiple />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <SaleStatusSelect v-model="saleStatusesFilter" label="Statut réservation" multiple />
                        </v-col>

                        <v-col v-if="getParameter('PRG_TYPERESIDENCE')" cols="12" lg="3">
                            <TypeResidenceSelect v-model="residenceTypesFilter" label="Type de résidence" multiple />
                        </v-col>

                        <v-col cols="12" lg="3">
                            <OptionStatusSelect v-model="optionStatusesFilter" label="Statut option" multiple />
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="2">
                            <v-btn color="primary" class="mr-4" dark depressed small @click="fetchProducts(true)">
                                <v-icon left x-small> fas fa-search </v-icon>
                                Rechercher
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-sheet>
            </v-card>
        </v-expand-transition>

        <ProductDialog ref="productDialog" :productId="selectedProductId" />

        <!-- Filters and products list -->
        <v-data-table class="elevation-2" :headers="headers" :items="products" item-key="id" hide-default-footer :items-per-page="-1" :show-expand="false" :single-expand="false">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item of items" :key="item.id">
                        <!-- Icone -->
                        <td class="text-center cursor-pointer" @click="openProductDialog(item)">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-icon large color="product-background" v-on="on">
                                        {{ getProductTypeIcon(item.type) }}
                                    </v-icon>
                                </template>
                                {{ item.typeLabel }}
                            </v-tooltip>

                            <br>

                            <div style="white-space: nowrap">
                                <template v-for="(product, i) of item.associated">
                                    <v-tooltip top :key="i">
                                        <template v-slot:activator="{ on }">
                                            <v-icon color="product-background" v-on="on" right x-small :class="{ 'ml-auto': i === 0, 'mr-auto': i === item.associated.length - 1, 'mr-1': i !== item.associated.length - 1 }" class="mx-auto">
                                                {{ getProductTypeIcon(product.type) }}
                                            </v-icon>
                                        </template>
                                        {{ product.typeLabel }}, {{ product.reference }}<template v-if="product.price > 0">, <span class="white-space-no-wrap"> {{ product.price | toCurrencyString() }} </span></template>
                                    </v-tooltip>
                                </template>
                            </div>
                        </td>

                        <!-- Lot -->
                        <td class="text-left cursor-pointer" @click="openProductDialog(item)">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ item.reference }}
                                    </span>
                                </template>
                                Référence
                            </v-tooltip> <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <v-chip v-text="item.statusLabel" :color="getProductStatusColor(item.status)" label small dark />
                                    </span>
                                </template>
                                Statut
                            </v-tooltip> <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        <template v-if="item.deliveryDate">
                                            <span v-if="item.deliveryDateType > 0">
                                                {{ item.deliveryDateType | toDeliveryDateType() }} {{ item.deliveryDate | toYearDate() }}
                                            </span>
                                            <span v-else>
                                                {{ item.deliveryDate | toDate() }}
                                            </span>
                                        </template>
                                        <template v-else-if="getConfig('programs.display_delivery_date', true) && item.program.deliveryDate">
                                            {{ item.program.deliveryDateType | toDeliveryDateType() }} {{ item.program.deliveryDate | toYearDate() }}
                                        </template>
                                    </span>
                                </template>
                                Date de livraison
                            </v-tooltip>
                        </td>

                        <!-- Descriptions -->
                        <td class="text-left cursor-pointer" @click="openProductDialog(item)">
                            <div>

                                <v-btn v-if="item.documents && getProductPlanDocument(item)" :href="getDocumentUrl(getProductPlanDocument(item).id)" target="_blank" small icon>
                                    <v-icon color="red darken-1" small> fas fa-file-pdf </v-icon>
                                </v-btn>

                                <v-tooltip v-if="item.typeLabel" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip color="transparent" label v-on="on">
                                            {{ item.typeLabel }}
                                        </v-chip>
                                    </template>
                                    Type
                                </v-tooltip>

                                <v-tooltip v-if="item.roomsLabel" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip color="transparent" label v-on="on">
                                            {{ item.roomsLabel }}
                                        </v-chip>
                                    </template>
                                    Nombre de pièces
                                </v-tooltip>

                                <v-tooltip v-if="item.floorLabel" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip color="transparent" label v-on="on">
                                            {{ item.floorLabel }}
                                        </v-chip>
                                    </template>
                                    Etage
                                </v-tooltip>

                                <v-tooltip v-if="item.exposureLabel" top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip color="transparent" label v-on="on">
                                            {{ item.exposureLabel }}
                                        </v-chip>
                                    </template>
                                    Exposition
                                </v-tooltip>
                            </div>

                            <v-tooltip v-if="item.surface" top>
                                <template v-slot:activator="{ on }">
                                    <v-chip color="transparent" label v-on="on">
                                        {{ item.surface | round() }} m²
                                    </v-chip>
                                </template>
                                Surface
                            </v-tooltip>

                            <template v-for="(surface, i) of item.otherSurfaces">
                                <v-chip v-if="item.type !== 31" color="transparent" label :key="i + surface.label" x-small>
                                    {{ surface.label }} {{ surface.surface | round() }} m²
                                </v-chip>
                            </template>
                            <br>

                            <v-chip class="mr-2 mb-1" label x-small v-for="(product, i) of item.associated" :key="i">
                                {{ product.typeLabel }} <template v-if="getConfig('products.display_associated_rooms', true)"> {{ product.roomsLabel }} </template> - {{ product.reference }}
                            </v-chip>
                        </td>

                        <!-- Prix -->
                        <td class="text-right cursor-pointer" @click="openProductDialog(item)">
                            <ProductPrices :product="item" />

                            <template v-if="item.program.feeAmount || item.program.feePercent">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-chip color="transparent" class="pr-0" label x-small v-on="on">
                                            Taux de rémunération
                                            <template v-if="item.program.feeAmount">
                                                {{ item.program.feeAmount | round(1) }} €
                                            </template>
                                            <template v-if="item.program.feePercent">
                                                {{ item.program.feePercent | round(1) }} %
                                            </template>
                                        </v-chip>
                                    </template>
                                    Taux de rémunération
                                </v-tooltip> <br>
                            </template>

                            <template v-if="item.priceForInvestor && getConfig('products.grid.display_price_for_investor', true)">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span class="white-space-no-wrap" v-on="on">
                                            {{ item.priceForInvestor | toCurrencyString() }}
                                        </span>
                                    </template>
                                    Prix investisseur
                                </v-tooltip> <br>
                            </template>

                            <v-tooltip v-if="item.fiscaliteLabel" top>
                                <template v-slot:activator="{ on }">
                                    <v-chip class="mb-1" small label v-on="on" :color="getFiscaliteColor(item.fiscalite)">
                                        {{ item.fiscaliteLabel }}
                                    </v-chip>
                                </template>
                                Fiscalité
                            </v-tooltip>
                        </td>

                        <!-- Montants -->
                        <td class="text-right cursor-pointer" @click="openProductDialog(item)">
                            <v-tooltip v-if="getConfig('products.grid.display_rent', true)" top>
                                <template v-slot:activator="{ on }">
                                    <span class="white-space-no-wrap" v-on="on" v-if="item.rent">
                                        {{ item.rent | toCurrencyString() }}
                                    </span>
                                </template>

                                {{ getConfig('products.rent_display_name', 'Loyer hors charges') }}
                            </v-tooltip> <br>

                            <v-tooltip v-if="getConfig('products.grid.display_rent_yield', true)" top>
                                <template v-slot:activator="{ on }">
                                    <v-chip color="transparent" class="pr-0" label x-small v-on="on" v-if="item.rent && item.priceFull">
                                        Taux de rendement brut
                                        {{ item.rent * 12 / item.priceFull * 100 | round(2) }} %
                                    </v-chip>
                                </template>

                                Taux de rendement brut
                            </v-tooltip> <br>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span class="white-space-no-wrap" v-on="on" v-if="item.notaryAmount">
                                        {{ item.notaryAmount | toCurrencyString() }}
                                    </span>
                                </template>
                                Frais de notaire
                            </v-tooltip>
                        </td>

                        <!-- Programme -->
                        <td v-if="!embeded" class="text-left">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-chip :to="`/programmes/${item.program.id}`" v-on="on" small label>
                                        {{ item.program.title }}
                                    </v-chip>
                                </template>
                                Nom du programme
                            </v-tooltip> <br>

                            <template v-if="item.program.residencetype">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <small v-on="on" > {{ item.program.residencetype }} </small>
                                    </template>
                                    Type de résidence
                                </v-tooltip> <br>
                            </template>

                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ item.program.city }}
                                    </span>
                                </template>
                                Ville
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <span v-on="on">
                                        {{ item.program.zip }}
                                    </span>
                                </template>
                                Code postal
                            </v-tooltip>
                        </td>

                        <!-- Surfaces -->
                        <td v-if="false" class="text-left">
                            <template v-if="item.Surface !== 0">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            {{ item.surface | round() }} m²
                                        </span>
                                    </template>
                                    Surface
                                </v-tooltip> <br>
                            </template>

                            <template v-if="item.surfaceTerrain !== 0">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            {{ item.surfaceTerrain | round() }} m²
                                        </span>
                                    </template>
                                    Surface terrain
                                </v-tooltip> <br>
                            </template>
                        </td>

                        <!-- Activity -->
                        <td class="text-left">
                            <ProductOptionChip v-if="item.activities.option" :product="item" :option="item.activities.option" />

                            <br>

                            <ProductSaleChip v-if="item.activities.sale" :product="item" :sale="item.activities.sale" />

                            <br>

                            <v-chip v-if="item.status === 2 && !item.activities.option && item.optionExpirationDate" color="light-blue" dark label small>
                                Prochaine disponibilité: {{ getProductExpirationDate(item.optionExpirationDate) | toDateStr('dd/MM/yyyy à kk:mm') }}
                            </v-chip>
                        </td>

                        <!-- Actions -->
                        <td class="text-center">
                            <OptionToSaleDialog v-if="item.activities.option && canConvertOptionToSale(item.activities.option.statusdetail)" @reload="fetchProducts(false, false)" :optionId="item.activities.option.activityId">
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="primary" class="mb-1 mr-1" depressed x-small dark v-on="{...menu, ...tooltip}">
                                                <v-icon x-small> fas fa-shopping-cart </v-icon>
                                            </v-btn>
                                        </template>
                                        Transformer l'option en réservation
                                    </v-tooltip>
                                </template>
                            </OptionToSaleDialog>

                            <ConfirmOptionDialog v-if="item.activities.option && canConfirmOption(item.activities.option.statusdetail)" @reload="fetchProducts(false, false)" :optionId="item.activities.option.activityId" :expirationDate="item.activities.option.expirationDate">
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="primary" depressed x-small class="mb-1 mr-1" dark v-on="{ ...menu, ...tooltip }">
                                                <v-icon x-small> fas fa-check-square </v-icon>
                                            </v-btn>
                                        </template>
                                        Confirmer l'option
                                    </v-tooltip>
                                </template>
                            </ConfirmOptionDialog>

                            <CancelOptionDialog v-if="item.activities.option && canCancelOption(item.activities.option.statusdetail)" @reload="fetchProducts(false, false)" :optionId="item.activities.option.activityId">
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="primary" depressed x-small class="mb-1 mr-1" dark v-on="{...menu, ...tooltip}">
                                                <v-icon x-small> fas fa-times-circle </v-icon>
                                            </v-btn>
                                        </template>
                                        Annuler l'option
                                    </v-tooltip>
                                </template>
                            </CancelOptionDialog>

                            <CreateOptionDialog v-if="item.status === 0" @reload="fetchProducts(false, false)" :initProgramId="item.program.id" :initProductId="item.id" isOption>
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="primary" class="mr-1" depressed x-small v-on="{ ...menu, ...tooltip }">
                                                <v-icon x-small>
                                                    fas fa-clock
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        Poser une option
                                    </v-tooltip>
                                </template>
                            </CreateOptionDialog>

                            <CreateOptionDialog v-if="getConfig('sales.enabled', false) && item.status === 0 && !getConfig('sales.sale_must_be_created_from_option', true)" :initProgramId="item.program.id" :initProductId="item.id" :isOption="false">
                                <template v-slot:activator="{ on: menu }">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on: tooltip }">
                                            <v-btn color="primary" depressed x-small v-on="{...menu, ...tooltip}">
                                                <v-icon x-small>
                                                    fas fa-shopping-cart
                                                </v-icon>
                                            </v-btn>
                                        </template>
                                        Enregistrer une réservation
                                    </v-tooltip>
                                </template>
                            </CreateOptionDialog>
                        </td>
                    </tr>
                    <tr v-if="items.length === 0">
                        <td class="text-center font-italic" colspan="100%"> Aucun lot ne correspond à votre recherche </td>
                    </tr>
                </tbody>
            </template>
        </v-data-table>
    </v-container>
</template>

<script>
import { addMinutes } from 'date-fns';

import ProductDialog from '../components/products/ProductDialog.vue';
import ProductPrices from '../components/products/ProductPrices.vue';
import DatePickerMenu from '../components/widgets/DatePickerMenu.vue';
import ProductSaleChip from '../components/products/ProductSaleChip.vue';
import SaleStatusSelect from '../components/widgets/SaleStatusSelect.vue';
import ProductTypeSelect from '../components/widgets/ProductTypeSelect.vue';
import ProductOptionChip from '../components/products/ProductOptionChip.vue';
import CancelOptionDialog from '../components/options/CancelOptionDialog.vue';
import CreateOptionDialog from '../components/dialogs/CreateOptionDialog.vue';
import ProductRoomsSelect from '../components/widgets/ProductRoomsSelect.vue';
import OptionStatusSelect from '../components/widgets/OptionStatusSelect.vue';
import OptionToSaleDialog from '../components/options/OptionToSaleDialog.vue';
import ConfirmOptionDialog from '../components/options/ConfirmOptionDialog.vue';
import ProgramAutocomplete from '../components/widgets/ProgramAutocomplete.vue';
import ProductStatusSelect from '../components/widgets/ProductStatusSelect.vue';
import TypeResidenceSelect from '../components/widgets/TypeResidenceSelect.vue';
import ProductActivitySelect from '../components/widgets/ProductActivitySelect.vue';
import ProgramLocationSelect from '../components/widgets/ProgramLocationSelect.vue';
import ProgramFiscaliteSelect from '../components/widgets/ProgramFiscaliteSelect.vue';

import optionsMixin from '../mixins/options.js';
import productsMixin from '../mixins/products.js';
import documentsMixin from '../mixins/documents.js';

export default {
    name: 'Products',

    mixins: [optionsMixin, productsMixin, documentsMixin],

    props: {
        programs: { type: Array, default: () => ([]) },
        embeded: { type: Boolean, default: false }
    },

    components: {
        ProductDialog,
        ProductPrices,
        DatePickerMenu,
        ProductSaleChip,
        SaleStatusSelect,
        ProductTypeSelect,
        ProductOptionChip,
        CreateOptionDialog,
        OptionStatusSelect,
        CancelOptionDialog,
        OptionToSaleDialog,
        ProductRoomsSelect,
        ConfirmOptionDialog,
        ProgramAutocomplete,
        ProductStatusSelect,
        TypeResidenceSelect,
        ProductActivitySelect,
        ProgramLocationSelect,
        ProgramFiscaliteSelect
    },

    data: () => ({
        displayAdvanceSearch: false,

        quickSearch: null,

        programsFilter: [],
        statusesFilter: [],
        fiscalitesFilter: [],
        residenceTypesFilter: [],
        typesFilter: [],
        locationsFilter: [],
        roomsFilter: [],
        budgetFilter: [0, 1000],
        beforeDeliveryDateFilter: '',
        activitiesFilter: [],
        saleStatusesFilter: [],
        optionStatusesFilter: [],

        page: 1,
        limit: 30,

        resetPage: false, // wether or not to reset the page to 1 when fetching the next products

        products: [],
        productsCount: 0,
        totalPages: 0,
        disableNavigation: false,
        places: [],

        advanceSearchDataFetched: false,
        selectedProductId: 0,

        headers: [
            {
                text: '',
                value: 'icon',
                sortable: false,
                align: 'center',
                width: '1%'
            },
            {
                text: 'Lot',
                value: 'product',
                sortable: false
            },
            {
                text: 'Descriptif',
                value: 'descriptions',
                sortable: false
            },
            {
                text: 'Prix de vente',
                value: 'prices',
                sortable: false,
                align: 'end'
            },
            {
                text: 'Autres montants',
                value: 'amounts',
                sortable: false,
                align: 'end'
            },
            {
                text: 'Programme',
                value: 'program',
                sortable: false
            },
            {
                text: 'Activité',
                value: 'activity',
                sortable: false
            },
            {
                text: 'Actions',
                value: 'actions',
                align: 'center',
                sortable: false,
                width: '10%'
            }
        ],

        order: 'status',
        defaultOrder: 'status',
        by: 'asc',
        defaultBy: 'asc',
        sortList: [
            {
                key: 'reference',
                name: 'Référence du lot',
                asc: [{ order: 'reference', by: 'asc' }],
                desc: [{ order: 'reference', by: 'desc' }]
            },
            {
                key: 'typologie',
                name: 'Typologie',
                asc: [{ order: 'type', by: 'asc' }, { order: 'rooms', by: 'asc' }],
                desc: [{ order: 'type', by: 'desc' }, { order: 'rooms', by: 'asc' }]
            },
            {
                key: 'status',
                name: 'Statut',
                asc: [{ order: 'status', by: 'asc' }, { order: 'reference', by: 'asc' }],
                desc: [{ order: 'status', by: 'desc' }, { order: 'reference', by: 'asc' }]
            },
            {
                key: 'surface',
                name: 'Surface',
                asc: [{ order: 'surface', by: 'asc' }],
                desc: [{ order: 'surface', by: 'desc' }]
            },
            {
                key: 'price',
                name: 'Prix de vente',
                asc: [{ order: 'totalPrice20', by: 'asc' }, { order: 'totalPriceReduced', by: 'asc' }],
                desc: [{ order: 'totalPrice20', by: 'desc' }, { order: 'totalPriceReduced', by: 'desc' }]
            },
            {
                key: 'program',
                name: 'Programme',
                asc: [{ order: 'program.title', by: 'asc' }, { order: 'reference', by: 'asc' }],
                desc: [{ order: 'program.title', by: 'desc' }, { order: 'reference', by: 'asc' }]
            },
            {
                key: 'localisation',
                name: 'Localisation',
                asc: [{ order: 'program.city', by: 'asc' }, { order: 'reference', by: 'asc' }],
                desc: [{ order: 'program.city', by: 'desc' }, { order: 'reference', by: 'asc' }]
            }
        ]
    }),

    methods: {
        buildQuery() {
            const item = this.sortList.find((i) => i.key === this.order);
            const fields = this.by === 'asc' ? item.asc : item.desc;

            let orderBy = [];
            for (const field of fields) {
                orderBy.push(`${field.order}:${field.by}`);
            }
            orderBy = orderBy.join(',');

            const query = {
                page: this.page,
                limit: this.limit,
                archived: 0,
                orderBy,
                include: 'associated,activities,documents'
            };
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.roomsFilter) && this.roomsFilter.length > 0) {
                query.rooms = this.roomsFilter.join(',');
            }
            if (Array.isArray(this.statusesFilter) && this.statusesFilter.length > 0) {
                query.statuses = this.statusesFilter.join(',');
            }
            if (Array.isArray(this.typesFilter) && this.typesFilter.length > 0) {
                query.types = this.typesFilter.join(',');
            }
            if (Array.isArray(this.fiscalitesFilter) && this.fiscalitesFilter.length > 0) {
                query.fiscalites = this.fiscalitesFilter.join(',');
            }
            if (Array.isArray(this.residenceTypesFilter) && this.residenceTypesFilter.length > 0) {
                query.residenceTypes = this.residenceTypesFilter.join(',');
            }
            if (Array.isArray(this.locationsFilter) && this.locationsFilter.length > 0) {
                query.locations = this.locationsFilter.join(',');
            }
            if (Array.isArray(this.activitiesFilter) && this.activitiesFilter.length > 0) {
                query.activities = this.activitiesFilter.join(',');
            }
            if (Array.isArray(this.saleStatusesFilter) && this.saleStatusesFilter.length > 0) {
                query.saleStatuses = this.saleStatusesFilter.join(',');
            }
            if (Array.isArray(this.optionStatusesFilter) && this.optionStatusesFilter.length > 0) {
                query.optionStatuses = this.optionStatusesFilter.join(',');
            }
            if (Array.isArray(this.budgetFilter) && this.budgetFilter.length > 0 && (this.budgetFilter[0] !== 0 || this.budgetFilter[1] !== 1000)) {
                const min = this.budgetFilter[0] === 0 ? '' : this.budgetFilter[0] * 1000;
                const max = this.budgetFilter[1] === 1000 ? '' : this.budgetFilter[1] * 1000;
                query.budget = `${min}:${max}`;
            }
            if (this.beforeDeliveryDateFilter) {
                query.beforeDeliveryDate = this.beforeDeliveryDateFilter;
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }
            return query;
        },

        buildDisplayQuery() {
            const query = {};
            if (this.page !== 1) {
                query.page = this.page;
            }
            if (Array.isArray(this.programsFilter) && this.programsFilter.length > 0) {
                query.programs = this.programsFilter.join(',');
            }
            if (Array.isArray(this.roomsFilter) && this.roomsFilter.length > 0) {
                query.rooms = this.roomsFilter.join(',');
            }
            if (Array.isArray(this.statusesFilter) && this.statusesFilter.length > 0) {
                query.statuses = this.statusesFilter.join(',');
            }
            if (Array.isArray(this.typesFilter) && this.typesFilter.length > 0) {
                query.types = this.typesFilter.join(',');
            }
            if (Array.isArray(this.fiscalitesFilter) && this.fiscalitesFilter.length > 0) {
                query.fiscalites = this.fiscalitesFilter.join(',');
            }
            if (Array.isArray(this.residenceTypesFilter) && this.residenceTypesFilter.length > 0) {
                query.residenceTypes = this.residenceTypesFilter.join(',');
            }
            if (Array.isArray(this.locationsFilter) && this.locationsFilter.length > 0) {
                query.locations = this.locationsFilter.join(',');
            }
            if (Array.isArray(this.activitiesFilter) && this.activitiesFilter.length > 0) {
                query.activities = this.activitiesFilter.join(',');
            }
            if (Array.isArray(this.saleStatusesFilter) && this.saleStatusesFilter.length > 0) {
                query.saleStatuses = this.saleStatusesFilter.join(',');
            }
            if (Array.isArray(this.optionStatusesFilter) && this.optionStatusesFilter.length > 0) {
                query.optionStatuses = this.optionStatusesFilter.join(',');
            }
            if (Array.isArray(this.budgetFilter) && this.budgetFilter.length > 0 && (this.budgetFilter[0] !== 0 || this.budgetFilter[1] !== 1000)) {
                const min = this.budgetFilter[0] === 0 ? '' : this.budgetFilter[0] * 1000;
                const max = this.budgetFilter[1] === 1000 ? '' : this.budgetFilter[1] * 1000;
                query.budget = `${min}:${max}`;
            }
            if (this.beforeDeliveryDateFilter) {
                query.beforeDeliveryDate = this.beforeDeliveryDateFilter;
            }
            if (this.quickSearch) {
                query.quickSearch = this.quickSearch;
            }

            if (this.order !== this.defaultOrder) {
                query.order = this.order;
            }
            if (this.by !== this.defaultBy) {
                query.by = this.by;
            }
            return query;
        },

        parseQuery(query) {
            this.page = parseInt(query.page) || 1;
            this.limit = parseInt(query.limit) || 30;
            this.programsFilter = query.programs ? query.programs.split(',').map((p) => parseInt(p)) : [];
            this.roomsFilter = query.rooms ? query.rooms.split(',').map((p) => parseInt(p)) : [];
            this.statusesFilter = query.statuses ? query.statuses.split(',').map((s) => parseInt(s)) : [];
            this.typesFilter = query.types ? query.types.split(',').map((t) => parseInt(t)) : [];
            this.fiscalitesFilter = query.fiscalites ? query.fiscalites.split(',') : [];
            this.residenceTypesFilter = query.residenceTypes ? query.residenceTypes.split(',') : [];
            this.locationsFilter = query.locations ? query.locations.split(',') : [];
            this.activitiesFilter = query.activities ? query.activities.split(',') : [];
            this.saleStatusesFilter = query.saleStatuses ? query.saleStatuses.split(',') : [];
            this.optionStatusesFilter = query.optionStatuses ? query.optionStatuses.split(',') : [];

            let [min, max] = query.budget ? query.budget.split(':').map((t) => parseInt(t)) : [0, 1000000];
            min = isNaN(min) ? 0 : min / 1000;
            max = isNaN(max) ? 1000 : max / 1000;
            this.budgetFilter = [min, max];

            this.beforeDeliveryDateFilter = query.beforeDeliveryDate || null;
            this.quickSearch = query.quickSearch || null;
            this.order = query.order ? query.order : this.order;
            this.by = query.by ? query.by : this.by;
        },

        doPush(query) {
            return (Object.keys(query).length !== Object.keys(this.$route.query).length) || !Object.keys(query).every((key) => query[key] === this.$route.query[key]);
        },

        async fetchProducts(push, resetPage = true) {
            try {
                this.disableNavigation = true;
                this.setLoading(true);

                if (resetPage) {
                    this.page = 1;
                }

                if (!this.embeded && push && this.doPush(this.buildDisplayQuery())) {
                    this.$router.push({ query: this.buildDisplayQuery() });
                }

                const query = this.buildQuery();
                const { products, count, totalPages, err } = await this.repos.products.getProducts(query);
                if (err) {
                    throw new Error(err);
                } else {
                    this.products = products;
                    this.productsCount = count;
                    this.totalPages = totalPages;
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rechargement de la page',
                    type: 'error'
                });
            } finally {
                this.disableNavigation = false;
                this.setLoading(false);
            }
        },

        toggleOrder() {
            this.by = this.by === 'asc' ? 'desc' : 'asc';
            this.fetchProducts(true);
        },

        async fetchPlaces() {
            try {
                const { places } = await this.repos.places.getPlaces();
                this.places = places;
            } catch (err) {
                console.error(err);
            }
        },

        getProductExpirationDate(expirationDate) {
            if (expirationDate) {
                return addMinutes(new Date(expirationDate), 20);
            } else {
                return null;
            }
        },

        getExportUrl() {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/programs/${this.programs[0]}/products/export`;
        },

        getExportColor() {
            switch (this.getConfig('products.export_type', '.xls')) {
                case '.pdf':
                    return 'red';
                case '.xls':
                default:
                    return 'green';
            }
        },

        getExportIcon() {
            switch (this.getConfig('products.export_type', '.xls')) {
                case '.pdf':
                    return 'fas fa-file-pdf';
                case '.xls':
                default:
                    return 'fas fa-file-excel';
            }
        },

        getExportTooltip() {
            switch (this.getConfig('products.export_type', '.xls')) {
                case '.pdf':
                    return 'Exporter la grille de prix complète au format PDF';
                case '.xls':
                default:
                    return 'Exporter la grille de prix complète au format Excel';
            }
        },

        getProductPlanDocument(product) {
            let document = product.documents.find((d) => (d.title && d.title.toLowerCase().includes('plan')) || (d.category && d.category.toLowerCase().includes('plan')));
            if (!document) {
                document = product.documents.find((d) => !d.category);
            }
            return document;
        },

        openProductDialog(product) {
            this.selectedProductId = product.id;
            this.$refs.productDialog.open();
        }
    },

    watch: {
        $route(to) {
            if (!this.disableNavigation) {
                if (!this.embeded) {
                    this.parseQuery(to.query);
                }
                this.fetchProducts(false);
            }
        },

        displayAdvanceSearch() {
            if (this.displayAdvanceSearch && !this.advanceSearchDataFetched) {
                this.advanceSearchDataFetched = true;
                this.fetchPlaces();
                this.$store.dispatch('products/fetchProductsSummary');
                this.$refs.programAutocomplete.fetchPrograms({ limit: 1000 });
            }
        }
    },

    created() {
        if (this.embeded) {
            this.programsFilter = this.programs;
            this.order = 'reference';
            this.headers = this.headers.filter((h) => h.value !== 'program');
            this.sortList = this.sortList.filter((i) => i.key !== 'program');
            this.sortList = this.sortList.filter((i) => i.key !== 'localisation');
        } else {
            this.parseQuery(this.$route.query);
        }
        this.fetchProducts(false, false);
        this.$store.dispatch('parameters/fetchParameters', ['PRG_TYPERESIDENCE']);
    }
};
</script>

<style lang="scss" scoped>
.pagination::v-deep .v-pagination {
    .v-pagination__navigation:last-child {
        margin-right: 0;
    }
}

.sort-pagination::v-deep {
    .v-input__control {
        max-width: 300px;
    }

    .v-input__append-outer {
        margin: 0 !important;
    }
}
</style>