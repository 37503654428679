<template>
    <v-alert :color="color" border="left" colored-border elevation="1" dense>
        <template v-if="document.isEmpty">
            <v-btn :color="color" class="ml-4" icon>
                <v-icon> fas fa-file-pdf </v-icon>
            </v-btn>
        </template>
        <template v-else>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-btn :color="color" class="mr-2" icon x-small v-on="on" :href="getDocumentUrl(document.id)" target="_blank">
                        <v-icon> fas fa-file-pdf </v-icon>
                    </v-btn>
                </template>
                Visualiser le document
            </v-tooltip>
        </template>

        <small v-text="document.title"> </small>
    </v-alert>
</template>

<script>
import documentsMixin from '../../mixins/documents';

export default {
    name: 'DocumentAlert',

    mixins: [documentsMixin],

    props: {
        document: { type: Object, required: true }
    },

    computed: {
        color() {
            return 'primary lighten-2';
        }
    }
};
</script>
