<template>
    <v-dialog v-model="isOpen" width="500px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Annulation de l'option
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                Vous êtes sur le point d'annuler votre option, saisissez éventuellement ci-dessous la raison de cette annulation.
                <v-textarea v-model="comment" placeholder="Message" rows="2" hide-details outlined dense />
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="error" small dark> Confirmer l'annulation </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Fermer </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'CancelOptionDialog',

    props: {
        optionId: { type: Number, required: true }
    },

    data: () => ({
        isOpen: false,

        comment: ''
    }),

    methods: {
        async submit() {
            try {
                this.setLoading(true);

                const body = {
                    comment: this.comment
                };

                const { success, err } = await this.repos.options.cancelOption(this.optionId, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('reload');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de l\'annulation de l\'option',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    }
};
</script>
