<template>
    <v-autocomplete v-model="selectedStatuses" :items="$store.state.products.statuses" :loading="isLoading" :disabled="disabled" item-value="key" :clear-icon="'far fa-times-circle'" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedStatuses)" :multiple="multiple" :label="label" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip :color="getProductStatusColor(data.item.key)" close @click:close="selectedStatuses.splice(data.index, 1)" label small dark>
                {{ data.item.label }}
            </v-chip>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    <v-chip :color="getProductStatusColor(item.key)" label small dark>
                        {{ item.label }}
                    </v-chip>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import productsMixin from '../../mixins/products.js';

export default {
    name: 'ProductStatusSelect',

    mixins: [productsMixin],

    props: {
        value: { required: true },
        label: { type: String, default: 'Statut' },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },

    data: () => ({
        selectedStatuses: null,
        isLoading: false,
        search: null,
        searchTimeout: null,
        items: []
    }),

    created() {
        this.selectedStatuses = this.value;
        if (this.$store.state.application.products) {
            this.items = this.$store.state.application.products.statuses;
        }
    }
};
</script>