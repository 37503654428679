<template>
    <span>
        <template v-if="displayPriceFull">
            <v-menu open-on-hover offset-y top>
                <template v-slot:activator="{ on: menu }">
                    <span v-on="{ ...menu }" class="white-space-no-wrap">
                        <template v-if="inline"> • </template>

                        <template v-if="displayReducedVatForFullPrice">
                            {{ product.vat || 20 }} %
                        </template>
                        <template v-else>
                            20 %
                        </template>
                        - {{ getProductFullPrice(product, 'priceFull') | toCurrencyString() }}
                    </span>
                </template>

                <v-card>
                    <v-card-title class="pa-1 title-1 px-4 white--text primary">
                        Prix de vente total - TVA
                        <template v-if="displayReducedVatForFullPrice">
                            {{ product.vat }} %
                        </template>
                        <template v-else>
                            20 %
                        </template>
                    </v-card-title>

                    <v-divider />

                    <v-card-text class="text-right">
                        <table>
                            <tr>
                                <td class="text-left pr-5">
                                    {{ product.typeLabel }}
                                </td>

                                <td> </td>

                                <td class="text-right white-space-no-wrap">
                                    {{ product.priceFull | toCurrencyString() }}
                                </td>
                            </tr>

                            <tr v-for="(associated, i) of product.associated" :key="i">
                                <td class="text-left pr-5">
                                    {{ associated.typeLabel }}
                                </td>
                                <td> +</td>

                                <td class="text-right white-space-no-wrap">
                                    {{ associated.priceFull | toCurrencyString() }}
                                </td>
                            </tr>

                            <tr>
                                <td class="text-left pr-5">
                                    Total
                                </td>

                                <td> </td>

                                <td class="text-right white-space-no-wrap" style="border-top: 1px solid black">
                                    {{ getProductFullPrice(product, 'priceFull') | toCurrencyString() }}
                                </td>
                            </tr>
                        </table>
                    </v-card-text>
                </v-card>
            </v-menu> <br v-if="!inline" />
        </template>

        <template v-if="displayPriceInvestor">
            <v-menu open-on-hover offset-y top>
                <template v-slot:activator="{ on: menu }">
                    <span v-on="{ ...menu }" class="white-space-no-wrap">
                        <template v-if="inline"> • </template>

                        <template v-if="product.vat">
                            {{ product.vat }} % -
                        </template>
                        {{ getProductFullPrice(product, 'priceInvestor') | toCurrencyString() }}
                    </span>
                </template>

                <v-card>
                    <v-card-title class="pa-1 title-1 px-4 white--text primary">
                        Prix de vente total
                        <template v-if="product.vat">
                            - TVA {{ product.vat }} %
                        </template>
                    </v-card-title>

                    <v-divider />

                    <v-card-text class="text-right">
                        <table>
                            <tr>
                                <td class="text-left pr-5">
                                    {{ product.typeLabel }}
                                </td>

                                <td> </td>

                                <td class="text-right white-space-no-wrap">
                                    {{ product.priceInvestor | toCurrencyString() }}
                                </td>
                            </tr>

                            <tr v-for="(associated, i) of product.associated" :key="i">
                                <td class="text-left pr-5">
                                    {{ associated.typeLabel }}
                                </td>
                                <td> +</td>

                                <td class="text-right white-space-no-wrap">
                                    {{ associated.priceInvestor | toCurrencyString() }}
                                </td>
                            </tr>

                            <tr>
                                <td class="text-left pr-5">
                                    Total
                                </td>

                                <td> </td>

                                <td class="text-right white-space-no-wrap" style="border-top: 1px solid black">
                                    {{ getProductFullPrice(product, 'priceInvestor') | toCurrencyString() }}
                                </td>
                            </tr>
                        </table>
                    </v-card-text>
                </v-card>
            </v-menu> <br v-if="!inline" />
        </template>
    </span>
</template>

<script>
import productsMixin from '../../mixins/products.js';

export default {
    name: 'ProductPricesInline',

    mixins: [productsMixin],

    props: {
        product: { type: Object, required: true },
        inline: { type: Boolean, default: false }
    },

    computed: {
        priceFull() {
            return this.getProductFullPrice(this.product, 'priceFull');
        },

        priceInvestor() {
            return this.getProductFullPrice(this.product, 'priceInvestor');
        },

        hasReducedVat() {
            return this.product.vat !== 20 && this.product.vat !== 0;
        },

        displayPriceFull() {
            if (!this.priceFull) {
                return false;
            }

            if (this.priceFull === this.priceInvestor && this.hasReducedVat) {
                return false;
            }

            return true;
        },

        displayPriceInvestor() {
            if (!this.priceInvestor) {
                return false;
            }

            if (this.priceFull === this.priceInvestor && !this.hasReducedVat) {
                return false;
            }

            return true;
        },

        displayReducedVatForFullPrice() {
            return this.priceFull && !this.priceInvestor && this.hasReducedVat;
        }
    }
};
</script>