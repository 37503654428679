<template>
    <v-dialog v-model="isOpen" width="700px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card v-if="product !== null">
            <v-card-title class="primary py-1 subtitle white--text">
                Lot {{ product.reference }}
            </v-card-title>

            <v-card-text class="pt-4 px-4">
                <v-row>
                    <v-col>
                        <v-chip :color="getProductStatusColor(product.status)" label small dark>
                            {{ product.statusLabel }}
                        </v-chip>

                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    {{ product.typeLabel }}
                                </span>
                            </template>
                            Type
                        </v-tooltip>

                        <v-tooltip top v-if="product.surface">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    •
                                    {{ product.surface | round() }} m²
                                </span>
                            </template>
                            Surface
                        </v-tooltip>

                        <ProductPrices :product="product" inline />

                        <v-tooltip top v-if="product.roomsLabel">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    •
                                    {{ product.roomsLabel }}
                                </span>
                            </template>
                            Pièces
                        </v-tooltip>

                        <v-tooltip top v-if="product.floorLabel">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    •
                                    {{ product.floorLabel }}
                                </span>
                            </template>
                            Etage
                        </v-tooltip>

                        <v-tooltip top v-if="product.exposureLabel">
                            <template v-slot:activator="{ on }">
                                <span v-on="on">
                                    •
                                    {{ product.exposureLabel }}
                                </span>
                            </template>
                            Exposition
                        </v-tooltip>
                    </v-col>
                </v-row>

                <v-divider class="my-4" />

                <v-row>
                    <v-col>
                        <template v-if="product.deliveryDate || (getConfig('programs.display_delivery_date', true) && product.program.deliveryDate)">
                            Date de livraison :
                            <template v-if="product.deliveryDate">
                                <span v-if="product.deliveryDateType > 0">
                                     {{ product.deliveryDateType | toDeliveryDateType() }} {{ product.deliveryDate | toYearDate() }}
                                </span>
                                <span v-else>
                                     {{ product.deliveryDate | toDate() }}
                                </span>
                            </template>
                            <template v-else-if="getConfig('programs.display_delivery_date', true) && product.program.deliveryDate">
                                {{ product.program.deliveryDateType | toDeliveryDateType() }} {{ product.program.deliveryDate | toYearDate() }}
                            </template>
                            <br>
                        </template>

                        <template v-if="product.surfaceTerrain && product.type !== 31 && product.type !== 1">
                            Surface terrain :
                            {{ product.surfaceTerrain | round() }} m²
                            <br>
                        </template>

                        <template v-if="product.vat">
                            Taux TVA :
                            {{ product.vat | round(1) }}%
                            <br>
                        </template>

                        <template v-if="product.priceInvestor">
                            Prix TVA réduite :
                            <span class="white-space-no-wrap"> {{ product.priceInvestor | toCurrencyString() }} </span>
                            <br>
                        </template>

                        <template v-if="product.priceForInvestor && getConfig('products.grid.display_price_for_investor', true)">
                            Prix investisseur :
                            <template v-if="product.priceForInvestor">
                                <span class="white-space-no-wrap">{{ product.priceForInvestor | toCurrencyString() }} </span>
                            </template>
                            <template v-else>
                                N/A
                            </template>
                            <br>
                        </template>

                        <template v-if="product.rent && getConfig('products.grid.display_rent', true)">
                            {{ getConfig('products.rent_display_name', 'Loyer hors charges') }} :
                            <template v-if="product.rent">
                                <span class="white-space-no-wrap"> {{ product.rent | toCurrencyString() }} </span>
                            </template>
                            <template v-else>
                                N/A
                            </template>
                            <br>
                        </template>

                        <template v-if="product.dues && getConfig('products.grid.display_rent', true)">
                            Charges :
                            <template v-if="product.dues">
                                <span class="white-space-no-wrap">{{ product.dues | toCurrencyString() }} </span>
                            </template>
                            <template v-else>
                                N/A
                            </template>
                            <br>
                        </template>

                        <template v-if="product.notaryamount">
                            Frais de notaire :
                            <span class="white-space-no-wrap"> {{ product.notaryamount | toCurrencyString() }} </span>
                            <br>
                        </template>

                        <template v-if="product.program.feeAmount || product.program.feePercent">
                            Taux de rémunération :
                            <template v-if="product.program.feeAmount">
                                {{ product.program.feeAmount | round(1) }} €
                            </template>
                            <template v-if="product.program.feePercent">
                                {{ product.program.feePercent | round(1) }} %
                            </template>
                            <br>
                        </template>

                        <template v-for="surface of product.otherSurfaces">
                            <v-chip v-if="product.type !== 31 || surface.id !== '15'" color="transparent" label :key="surface.id" x-small>
                                {{ surface.label }} {{ surface.surface | round() }} m²
                            </v-chip>
                        </template>
                    </v-col>

                    <v-divider vertical />

                    <v-col>
                        <v-list class="py-0">
                            <v-list-item v-for="associated of product.associated" :key="associated.id" class="px-0">
                                <v-list-item-icon class="mr-2">
                                    <v-icon color="contact-foreground"> {{ getProductTypeIcon(associated.type) }} </v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ associated.reference }}
                                        <template v-if="associated.typeLabel">
                                            • {{ associated.typeLabel }}
                                        </template>
                                        <template v-if="associated.roomsLabel">
                                            • {{ associated.roomsLabel }}
                                        </template>

                                        <ProductPrices :product="associated" inline />
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                </v-row>

                <v-divider class="my-4" />

                <v-row>
                    <v-col cols="6">
                        <DocumentAlert v-for="document of product.documents" :key="document.id" :document="document" />
                    </v-col>
                </v-row>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-spacer />

                <v-btn @click="isOpen = false" depressed small>
                    Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import productsMixin from '../../mixins/products.js';
import DocumentAlert from '../program/DocumentAlert.vue';
import ProductPrices from './ProductPrices.vue';

export default {
    name: 'ProductDialog',

    mixins: [productsMixin],

    components: {
        DocumentAlert,
        ProductPrices
    },

    props: {
        productId: { type: Number }
    },

    data: () => ({
        isOpen: false,

        product: null
    }),

    watch: {
        isOpen(isOpen) {
            if (isOpen && (this.product === null || this.product.id !== this.productId)) {
                this.fetchProduct();
            }
        }
    },

    methods: {
        async fetchProduct() {
            try {
                this.setLoading(true);

                const query = {
                    include: 'documents,associated',
                    allowPreviousProduct: 1
                };
                const { product } = await this.repos.products.getProduct(this.productId, query);
                this.product = product;
            } catch (err) {
                console.error(err);
            } finally {
                this.setLoading(false);
            }
        },

        open() {
            this.isOpen = true;
        }
    }
};
</script>
