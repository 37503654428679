var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_vm._t("activator",null,null,{ on: on })]}}],null,true),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-toolbar',{attrs:{"dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"title primary--text"},[_vm._v(" Confirmation de l'option ")]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"primary","icon":""},on:{"click":function($event){_vm.isOpen = false}}},on),[_c('v-icon',[_vm._v(" far fa-times-circle ")])],1)]}}])},[_vm._v(" Fermer la fenêtre ")])],1),_c('v-divider'),_c('v-card-text',{staticClass:"pt-4"},[_c('ValidationObserver',{ref:"observer"},[(_vm.getConfig('options.enable_planned_sign_date', false))?[_vm._v(" Pour confirmer cette option, veuillez saisir la date de signature prévue avec votre contact. "),_c('ValidationProvider',{attrs:{"name":"Date de signature","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var failed = ref.failed;
return [_c('DatePickerMenu',{attrs:{"errors":errors,"failed":failed,"placeholder":"Date de signature"},model:{value:(_vm.dateConfirmation),callback:function ($$v) {_vm.dateConfirmation=$$v},expression:"dateConfirmation"}})]}}],null,false,4113189253)})]:[(_vm.getConfig('options.confirm_expired_date_extension', 3) > 0)?[_vm._v(" Votre option sera valable pendant "+_vm._s(_vm.getConfig('options.confirm_expired_date_extension', 3))+" jours complémentaires (date d'expiration : "+_vm._s(_vm._f("toDate")(_vm.newEpirationDate))+" à "+_vm._s(_vm._f("toTime")(_vm.newEpirationDate))+") ")]:_vm._e()]],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","small":"","dark":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v(" Confirmer ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.isOpen = false}}},[_vm._v(" Annuler ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }