<template>
    <v-dialog v-model="isOpen" width="500px">
        <template v-slot:activator="{ on }">
            <slot name="activator" v-bind="{ on }" />
        </template>

        <v-card>
            <v-toolbar dense flat>
                <v-toolbar-title class="title primary--text">
                    Confirmation de l'option
                </v-toolbar-title>

                <v-spacer />

                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn @click="isOpen = false" color="primary" icon v-on="on">
                            <v-icon> far fa-times-circle </v-icon>
                        </v-btn>
                    </template>
                    Fermer la fenêtre
                </v-tooltip>
            </v-toolbar>

            <v-divider />

            <v-card-text class="pt-4">
                <ValidationObserver ref="observer">
                    <template v-if="getConfig('options.enable_planned_sign_date', false)">
                        Pour confirmer cette option, veuillez saisir la date de signature prévue avec votre contact.

                        <ValidationProvider v-slot="{ errors, failed }" name="Date de signature" rules="required">
                            <DatePickerMenu v-model="dateConfirmation" :errors="errors" :failed="failed" placeholder="Date de signature" />
                        </ValidationProvider>
                    </template>
                    <template v-else>
                        <template v-if="getConfig('options.confirm_expired_date_extension', 3) > 0">
                            Votre option sera valable pendant {{ getConfig('options.confirm_expired_date_extension', 3) }} jours complémentaires (date d'expiration : {{ newEpirationDate | toDate() }} à {{ newEpirationDate | toTime() }})
                        </template>
                    </template>
                </ValidationObserver>
            </v-card-text>

            <v-divider />

            <v-card-actions>
                <v-btn @click="submit()" color="primary" small dark> Confirmer </v-btn>

                <v-spacer />

                <v-btn @click="isOpen = false" small> Annuler </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { addDays } from 'date-fns';

import DatePickerMenu from '../widgets/DatePickerMenu.vue';

export default {
    name: 'ConfirmOptionDialog',

    props: {
        optionId: { type: Number, required: true },
        expirationDate: { type: String }
    },

    components: {
        DatePickerMenu
    },

    data: () => ({
        isOpen: false,

        dateConfirmation: null
    }),

    methods: {
        async submit() {
            const valid = await this.$refs.observer.validate();
            if (!valid) {
                return;
            }

            try {
                this.setLoading(true);

                const body = {
                    dateConfirmation: this.dateConfirmation
                };

                const { success, err } = await this.repos.options.confirmOption(this.optionId, body);
                if (success) {
                    this.isOpen = false;
                    this.$emit('reload');
                } else {
                    throw new Error(err);
                }
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors de l\'annulation de l\'option',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        newEpirationDate() {
            return addDays(new Date(), this.getConfig('options.confirm_expired_date_extension', 3));
        }
    }
};
</script>
