<template>
    <v-autocomplete v-model="selectedActivities" :items="productActivities" :disabled="disabled" item-value="id" clear-icon="far fa-times-circle" :menu-props="{ bottom: true, offsetY: true }" @change="$emit('input', selectedActivities)" :multiple="multiple" :label="label" background-color="white" hide-details no-filter outlined clearable dense>
        <template v-slot:selection="data">
            <v-chip color="accent" label small dark close @click:close="selectedActivities.splice(data.index, 1)">
                {{ data.item.label }}
            </v-chip>
        </template>
        <template v-slot:item="{ item }">
            <v-list-item-content>
                <v-list-item-title>
                    <v-chip color="accent" label small dark>
                        {{ item.label }}
                    </v-chip>
                </v-list-item-title>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
import productsMixin from '../../mixins/products.js';

export default {
    name: 'ProductActivitySelect',

    mixins: [productsMixin],

    props: {
        value: { required: true },
        label: { type: String, default: 'Activité' },
        disabled: { type: Boolean, default: false },
        multiple: { type: Boolean, default: false }
    },

    data: () => ({
        selectedActivities: null
    }),

    created() {
        this.selectedActivities = this.value;
    }
};
</script>