<template>
    <v-menu open-on-hover left offset-x>
        <template v-slot:activator="{ on }">
            <v-chip :color="getOptionStatus(option.statusdetail, 'color')" class="my-1" label small dark v-on="on">
                Option - {{ getOptionStatus(option.statusdetail, 'statusLabel') }}
            </v-chip>

            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-chip v-if="option.count > 1" label small class="ml-1" v-on="on">
                        + {{ option.count - 1 }}
                    </v-chip>
                </template>
                Vous avez passé {{ option.count - 1}} autre{{ option.count > 2 ? 's' : '' }} option{{ option.count > 2 ? 's' : '' }} sur ce lot
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title :class="`py-1 white--text subtitle ${getOptionStatus(option.statusdetail, 'color')}`">
                {{ getOptionStatus(option.statusdetail, 'label') }}
            </v-card-title>

            <v-list dense>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ option.activityDate | toDate() }} à {{ option.activityDate | toTime() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Date de création
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ option.contact.name }} {{ option.contact.firstname }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            Contact
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ option.statusdate | toDate() }}
                        </v-list-item-title>

                        <v-list-item-subtitle>
                            {{ option.statusdatelabel }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-card>
    </v-menu>
</template>

<script>
import optionsMixin from '../../mixins/options.js';

export default {
    name: 'ProductOptionChip',

    mixins: [optionsMixin],

    props: {
        product: { type: Object },
        option: { type: Object }
    }
};
</script>
